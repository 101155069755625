import stages from "../../environments.js";

if (!Object.keys(stages).includes(process.env.VUE_APP_BUILD_ENV)) {
  throw new ReferenceError(
    `The stage '${process.env.VUE_APP_BUILD_ENV}' is not configured`
  );
}

export default {
  environment: process.env.VUE_APP_BUILD_ENV,
  pages: {
    alarmList: {
      activeObject: null
    }
  },
  authentication: {
    url: null,
    client_id: null,
    redirect_uri: null
  },
  authenticationTokens: {
    id_token: null,
    access_token: null,
    refresh_token: null,
    expires_in: null,
    token_type: null
  },
  alarmApi: {
    endpoint: null
  },
  error: {
    tz: null,
    msg: null
  },
  success: {
    tz: null,
    msg: null
  },
  alarms: [],
  alarmActors: {},
  alarmGroups: {},
  sortedAlarmGroups: {
    acknowledged: [],
    unacknowledged: []
  },
  selectedAlarms: {
    acknowledged: {},
    unacknowledged: {}
  },
  sorting: {
    acknowledged: {
      direction: "desc",
      attr: "timeslot"
    },
    unacknowledged: {
      direction: "desc",
      attr: "timeslot"
    },
    archived: {
      direction: "desc",
      attr: "timeslot"
    }
  },
  filter: {},
  loading: {
    acknowledged: false,
    unacknowledged: false
  },
  archive: {
    alarms: [],
    loading: false
  },
  activeSpotfire: null,
  ...stages[process.env.VUE_APP_BUILD_ENV]
};
