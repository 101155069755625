<template>
  <spotfire-layout :appUrl="appUrl">
    <template v-slot:SpotfireSubLayout>
      <div class="md-layout">
        <div
          id="spotfire"
          class="full-size-embed md-layout-item"
        ></div>
      </div>
    </template>
  </spotfire-layout>
</template>

<script>
  import SpotfireLayout from "./SpotfireLayout.vue";

  export default {
    name: "spotfire-full-layout",
    components: {
      "spotfire-layout": SpotfireLayout
    },
    data() {
      return {};
    },
    props: {
      appUrl: String
    }
  }
</script>