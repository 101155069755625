<template>
  <spotfire-column-layout :appUrl="getSpotfireApplications.alarmHistoryApplicationUrl">
    <template v-slot:ListView="listView">
      <div class="column-layout-panel md-layout-item md-layout">
        <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
          <md-card>
            <md-card-header data-background-color="blue">
              <div class="md-title">
                <span>Alarm History</span>
              </div>
              <div class="md-subhead">
                <span>List of closed alarms</span>
              </div>
            </md-card-header>
            <md-card-content>
              <single-list
                type="archived"
                :alarms="getManagedArchivedAlarms"
                :loading="getArchiveLoading"
                @onItemClick="listView.onItemClick"
              />
              <md-divider />
            </md-card-content>
          </md-card>
        </div>
      </div>
    </template>
  </spotfire-column-layout>
</template>

<script>
import SpotfireColumnLayout from "./Layout/SpotfireColumnLayout.vue";
import { SingleList } from "@/components";
import {
  mapGetters,
  mapActions,
  mapMutations,
  mapState
} from "vuex";

export default {
  name: "alarm-history-list",
  components: {
    "single-list": SingleList,
    "spotfire-column-layout": SpotfireColumnLayout
  },
  data() {
    return {};
  },
  mounted() {
    this.loadArchivedAlarms()
  },
  computed: {
    ...mapGetters([
      "getManagedArchivedAlarms",
      "getArchiveLoading",
      "getSpotfireApplications"
    ])
  },
  methods: {
    ...mapActions(["loadArchivedAlarms"]),
    onItemClick(alarm) {
      this.$emit("onItemClick", alarm);
    }
  }
};
</script>