<template>
  <div class="list-month-filter">
    <md-field>
      <label :for="type + '-month-filter'">Month</label>
      <md-select
        v-model="currentMonth"
        md-dense
        :name="type + '-month-filter'"
        :id="type + '-month-filter'"
      >
        <md-option
          v-for="(label, month) in availableMonths"
          :key="month"
          :value="month"
        >
          {{ label }}
        </md-option>
      </md-select>
    </md-field>
  </div>
</template>

<script>
  import {mapActions, mapGetters} from "vuex";

  export default {
    name: "list-month-filter",
    props: {
      type: String
    },
    computed: {
      ...mapGetters(["getMonthFilter"]),
      availableMonths: () => {
        const now = +new Date();
        const months = {};

        let i = 0;
        for (i; i < 6; i++) {
          const date = new Date(now  - (1000 * 60 * 60 * 24 * 30 * i));
          const month = ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1);
          months[date.getFullYear() + month] = date.toLocaleString('en-GB', { month: 'long', timezone: 'UTC' });
        }

        return months;
      },
      currentMonth: {
        get() {
          return this.getMonthFilter(this.type);
        },
        set(data) {
          // This might currently cause a vue-material warning (which is caused by a bug),
          // no fix has been released yet. Does not appear to cause any trouble though.
          this.changeMonthFilter({
            type: this.type,
            value: data
          });
        }
      }
    },
    methods: {
      ...mapActions(["changeMonthFilter"])
    }
  }
</script>

<style lang="scss">
  .list-month-filter {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 0.5em;

    .md-field {
      width: unset;
      padding: 0;
      margin: 0;
      height: unset;
      min-height: unset;

      .md-select {
        .md-input {
          padding-bottom: 0;
          padding-top: 1em;
        }

        .md-icon {
          margin-bottom: 0;
        }
      }
    }
  }

  .md-select-menu {
    .md-list-item-text {
      position: unset;
    }
  }
</style>