<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar activeColor="blue">
      <sidebar-link
        v-for="link in links"
        :key="link.path"
        :to="link.path"
        :link="link"
        :title="link.name"
      />
    </side-bar>
    <div class="main-panel">
      <dashboard-content> </dashboard-content>
    </div>
  </div>
</template>

<script>
import DashboardContent from "./DashboardContent.vue";

export default {
  components: {
    DashboardContent
  },
  data() {
    return {
      links: [
        {
          path: "/alarms",
          icon: "warning",
          name: "Alarm List"
        },
        {
          path: "/history",
          icon: "history",
          name: "Alarm History"
        },
        {
          path: "/dashboard",
          icon: "tv",
          name: "Dashboard"
        },
        {
          path: "/nfm-violations",
          icon: "wifi_tethering_error_rounded",
          name: "NFM Violations"
        },
        {
          path: "/rp-validation",
          icon: "travel_explore",
          name: "RP Validation"
        },
        {
          path: "/archive",
          icon: "inventory_2",
          name: "Alarm Archive"
        },
        {
          path: "/unauthorized-usage",
          icon: "vpn_lock",
          name: "Unauthorized Usage"
        },
        {
          path: "/top-aggressive",
          icon: "format_list_numbered",
          name: "Top Aggressive",
        }
      ]
    }
  }
};
</script>
