<template>
  <div class="content">
    <div class="md-layout md-alignment-top-center">
      <div class="md-layout-item md-xsmall-size-40 md-small-size-40 md-medium-size-40 md-large-size-20 md-xlarge-size-20">
           <div v-if="!hasActive('unack')">
           <md-empty-state
              md-icon=""
              md-label="Active Unacknowledged"
              :md-description="getAlarmsActiveUnAcknowledgedCount"
              style="background-color:YellowGreen">
           </md-empty-state>
           </div>
           <div v-if="hasActive('unack')">
           <md-empty-state
              md-icon=""
              md-label="Active Unacknowledged"
              :md-description="getAlarmsActiveUnAcknowledgedCount"
              style="background-color:Tomato">
           </md-empty-state>
           </div>
      </div>
      <div class="md-layout-item md-xsmall-size-40 md-small-size-40 md-medium-size-40 md-large-size-20 md-xlarge-size-20">
          <div v-if="!hasActive('ack')">
          <md-empty-state
              md-icon=""
              md-label="Active Acknowledged"
              :md-description="getAlarmsActiveAcknowledgedCount"
              style="background-color:YellowGreen">
          </md-empty-state>
          </div>
          <div v-if="hasActive('ack')">
          <md-empty-state
              md-icon=""
              md-label="Active Acknowledged"
              :md-description="getAlarmsActiveAcknowledgedCount"
              style="background-color:Tomato">
          </md-empty-state>
          </div>
      </div>
      <div
        class="md-layout-item md-xsmall-size-95 md-small-size-95 md-medium-size-95 md-large-size-60 md-xlarge-size-60"
        v-show="alarmAgeChart.data.labels.length"
      >
        <ChartCard
            :key="chardRenderId"
            :chart-data="alarmAgeChart.data"
            :chart-options="alarmAgeChart.options"
            :chart-type="'Bar'"
            data-background-color="red">
            <template slot="title">
                <h4 class="chart-title">Unack Age (in Hours) - TOP 10</h4>
            </template>
        </ChartCard>
      </div>
         <div class="md-layout-item md-size-90 pt-20">
          <md-table v-model="getAlarmsActive" table-header-color="blue">
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Alarm">
                  <h4>{{ item.protocol }}-{{ item.detectionPrinciple }}</h4>
              </md-table-cell>
              <md-table-cell md-label="Actor" style="font-size:1.8rem">
                <h4>{{ item.actorType }} | {{ item.actorName }}</h4>
              </md-table-cell>
              <md-table-cell md-label="Timeslot">
                <h4>{{ item.timeslot}}</h4>
              </md-table-cell>
              <md-table-cell md-label="Score">
                  <h4>{{ item.triggered_event | getScore | truncate }}</h4>
              </md-table-cell>
              <md-table-cell md-label="Current">
                  <h4> {{item.current_event | getScore | truncate }}</h4>
              </md-table-cell>
            </md-table-row>
          </md-table>
        </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .content {
    padding: 5px;
  }
</style>

<script>
import { mapGetters } from "vuex";
import { ChartCard } from "@/components";

export default {
  components: {
    ChartCard
  },
  computed: {
    alarmsActive: () => [],
    ...mapGetters([
      "getError",
      "getAlarmsActive",
      "getAlarmsActiveAcknowledgedCount",
      "getAlarmsActiveUnAcknowledgedCount",
      "getAlarmsActiveUnacknowledgedTopSeries"
    ])
  },
  watch: {
    getAlarmsActiveUnacknowledgedTopSeries: {
      immediate: true,
      handler(newSeries, oldSeries) {
        this.alarmAgeChart.data = newSeries.data;
        this.alarmAgeChart.options = {
          ...this.alarmAgeChart.options
        };
        this.chardRenderId++;
      }
    }
  },
  filters: {
    getScore: event =>
      event
        .replace(/,".*"/g, "")
        .split(",")
        .pop(),
    truncate: value => Number.parseFloat(value).toFixed(2)
  },
  data() {
    return {
      chardRenderId: 0,
      alarmAgeChart: {
        data: {
          labels: [],
          series: []
        },
        options: {
          stretch: true,
          distributeSeries: true,
          horizontalBars: true,
          reverseData: true,
          axisX: {
            showLabel: true
          },
          axisY: {
            offset: 400
          },
          low: 0,
          onlyInteger: true,
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          }
        }
      }
    };
  },
  methods: {
      hasActive(group) {
        if (group === 'unack') {
            var c = this.getAlarmsActiveUnAcknowledgedCount
        }
        else if (group === 'ack'){
            var c = this.getAlarmsActiveAcknowledgedCount
        }
        else {
            var c = 0
        }
        return (c > 0 ? true : false)
    },
  }
};
</script>

<style lang="scss">
.md-content.md-theme-default {
  background-color: transparent !important;
}

.md-layout-item {
    padding-left: 0px;
}

.md-table-head-label {
  font-size: 1.3rem;
  text-align: left;
  margin: 0.5em 0em;
  padding-left: 1.0em;
}

.md-empty-state {
  height: 95%;
  width: 95%;
  margin: 0.2em 1em;
}

.md-empty-state-label {
  font-size: 2rem;
  font-weight: 500;
}

.md-empty-state-description {
  margin: 0.5em 0;
  font-size: 10rem;
  font-weight: 900;
}

.md-card-header {
  padding-top:0px;
  padding-left:0px;
  margin: 0.2em 1em;
  height: 100%;
}

.md-card-header.card-chart {
  margin: -25px -15px;
  width: 95%;
  height: 300px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-20 {
  padding-top: 20px;
}

svg.ct-chart-bar, svg.ct-chart-line{
    overflow: visible;
    height: 100%;
}

.chart-title {
  font-size: 2rem;
  margin: 0em;
  text-align: center;
  font-weight: 550;
}

.ct-label {
  font-size: 1.1rem;
  margin: 0em 0em;
  text-align: right;
  font-weight: 700;
  height: 100%;
}

.ct-bar {
  stroke-width: 10px;
}

.ct-chart {
    margin: auto;
    width: 100%;
    height: 100%;
}

.ct-label.ct-horizontal {
  position: relative;
  transform: rotate(90deg);
  transform-origin: left top;
  font-size: 0.9rem;
}
</style>