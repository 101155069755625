import { sortAlarms } from "./helpers.js";

const isAcknowledged = (alarm) => (alarm.acknowledged === "true" ? true : false);
const isActive = (alarm) => (alarm.state === "Active" ? true : false);

export default {
  getAuthenticationLoginUrl: ({ authentication: auth }) => (
    `https://${auth.url}/login?client_id=${auth.client_id}&redirect_uri=${auth.redirect_uri}&response_type=code`
  ),
  getAuthenticationOauthUrl: ({ authentication: auth }) => (
    `https://${auth.url}/oauth2/token`
  ),
  getAuthenticationClientId: ({ authentication: auth }) => (
    auth.client_id
  ),
  getAuthenticationRedirectUri: ({ authentication: auth }) => (
    auth.redirect_uri
  ),
  getError: state => {
    return state.error.msg;
  },
  getAuthTokens: state => state.authenticationTokens,
  getUnacknowledgedAlarmGroups: ({ sortedAlarmGroups }) => (
    sortedAlarmGroups.unacknowledged
  ),
  getAcknowledgedAlarmGroups: ({ sortedAlarmGroups }) => (
    sortedAlarmGroups.acknowledged
  ),
  getAlarms: ({ alarms }) => alarms,
  getAlarmsActive: (state, { getAlarms }) => {
    return getAlarms.filter(isActive);
  },
  getAlarmsAcknowledged: (state, { getAlarms }) => {
    return getAlarms.filter(isAcknowledged);
  },
  getAlarmsUnacknowledged: (state, { getAlarms }) => {
    return getAlarms.filter(a => !isAcknowledged(a));
  },
  getAlarmsActiveAcknowledgedCount: (state, { getAlarmsActive }) => {
    return getAlarmsActive.filter(isAcknowledged).length.toString();
  },
  getAlarmsActiveUnAcknowledgedCount: (state, { getAlarmsActive }) => {
    return getAlarmsActive.filter(a => !isAcknowledged(a)).length.toString();
  },
  getAlarmsActiveUnacknowledgedTopSeries: (
    state,
    {
      getAlarmsUnacknowledged: alarms
    }
  ) => {
    const alarmSerie = alarms.map((a) => ({
      label: `${
        a.actorName.length > 29
          ? a.actorName.substring(0, 40) + ".."
          : a.actorName
      } - ${a.alarmName.substring(0, a.alarmName.indexOf("-"))}`,
      serie: Date.parse(a.timeslot)
    }));

    const alarmTop10 = alarmSerie.sort((a, b) => (
      a.serie < b.serie ? -1 : 1
    )).slice(0, 10);

    const labels = alarmTop10.map(a => a.label);
    const series = alarmTop10.map(a => (Date.now() - a.serie) / 3600000);
    const high = Math.ceil(Math.max(...series) / 100) * 100;

    return {
      data: {
        labels,
        series
      },
      options: {
        high
      }
    };
  },
  getSelectedState: (state) => ({groupOpts, alarmOpts}) => {
    if (groupOpts) {
      const {actor, type} = groupOpts
      const alarmIds = state.alarmGroups[type][actor].items.map((a) => a.alarmId);
      return alarmIds.every((id) => state.selectedAlarms[type][id]);
    } else if (alarmOpts) {
      const {type, alarmId} = alarmOpts
      return state.selectedAlarms[type][alarmId];
    }

    return false;
  },
  getActionLoading: (state) => (type) => {
    return state.loading[type];
  },
  getSorting: (state) => (type) => {
    return state.sorting[type];
  },
  getIsActiveSpotfire: (state) => (alarm) => {
    return state.activeSpotfire &&
      state.activeSpotfire.actor === alarm.actor &&
      state.activeSpotfire.protocol === alarm.protocol &&
      state.activeSpotfire.detectionPrinciple === alarm.detectionPrinciple &&
      state.activeSpotfire.timeslot === alarm.timeslot;
  },
  getArchivedAlarms: ({ archive }) => {
    return archive.alarms;
  },
  getManagedArchivedAlarms: ({ archive, filter, sorting }) => {
    const archiveFilter = filter.archived || {}
    const searchTerm = archiveFilter.search && archiveFilter.search.value || "";
    let filtered = archive.alarms;

    if (searchTerm.length > 0) {
      filtered = archive.alarms.filter((alarm) => {
        return alarm.actorName.indexOf(searchTerm) >= 0 || alarm.actor.indexOf(searchTerm) >= 0
      })
    }

    return sortAlarms(filtered, sorting.archived)
  },
  getArchiveLoading: ({ archive }) => {
    return archive.loading;
  },
  getMonthFilter: ({ filter }) => (type) => {
    if (filter[type] && filter[type].month) {
      return filter[type].month;
    }

    const date = new Date();
    const month = ((date.getMonth() + 1) < 10 ? '0' : '') + (date.getMonth() + 1);
    return date.getFullYear() + month;
  },
  getFilterActors: (state) => (type) => {
    if (type !== "archived") {
      return [];
    }

    const actors = state.archive.alarms.reduce((result, alarm) => {
      result[alarm.actor] = alarm.actorName;
      return result;
    }, {})

    return Object.keys(actors).map((actor) => {
      return {
        label: actors[actor],
        value: actor
      }
    })
  },
  getSearchValue: ({ filter }) => (type) => {
    if (filter[type]) {
      return filter[type].search || null;
    }

    return null;
  },
  getSpotfireLoginUrl: ({ spotfire }) => {
    return spotfire.loginUrl;
  },
  getSpotfireScriptUrl: ({ spotfire }) => {
    return spotfire.scriptUrl;
  },
  getSpotfireApplications: ({ spotfire }) => {
    return spotfire.applications;
  },
};
