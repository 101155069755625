<template>
  <transition name="fade" mode="out-in">
    <router-view :name="viewName"></router-view>
  </transition>
</template>

<script>
export default {
  props: {
    viewName: {
      type: String,
      default: "default"
    }
  }
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}

.fade-enter,
  .fade-leave-to
    /* .fade-leave-active in <2.1.8 */

 {
  opacity: 0;
}
</style>
