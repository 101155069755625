// =========================================================
// * Vue Material Dashboard - v1.3.2
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vue-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
// * Licensed under MIT (https://github.com/creativetimofficial/vue-material-dashboard/blob/master/LICENSE.md)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App";
import store from "./store";

// Sidebar on the right. Used as a local plugin in DashboardLayout.vue
import SideBar from "./components/SidebarPlugin";

// asset imports
import VueMaterial from "vue-material";
import "vue-material/dist/vue-material.min.css";
import "./assets/scss/material-dashboard.scss";
import "./assets/scss/skynet.scss";

// library auto imports
import "es6-promise/auto";

// configure router
import Layout from "@/pages/Layout/AlarmManagementLayout.vue";
import AlarmList from "@/pages/AlarmList.vue";
import AlarmHistory from "@/pages/AlarmHistory.vue";
import AlarmArchive from "@/pages/AlarmArchive.vue";
import AlarmDashboard from "@/pages/AlarmDashboard.vue";
import NfmViolations from "@/pages/NfmViolations.vue";
import RpValidation from "@/pages/RpValidation.vue";
import UnauthorizedUsage from "@/pages/UnauthorizedUsage.vue";
import TopAggressive from "@/pages/TopAggressive.vue";
import { mapActions, mapMutations } from "vuex";

import Chartist from "chartist";
Vue.prototype.$Chartist = Chartist;
Vue.config.devtools = process.env.NODE_ENV === "development";

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      component: Layout,
      redirect: "/alarms",
      children: [
        {
          path: "alarms",
          name: "Alarm List",
          component: AlarmList
        },
        {
          path: "history",
          name: "Alarm history",
          component: AlarmHistory
        },
        {
          path: "dashboard",
          name: "Bigscreen Dashboard",
          component: AlarmDashboard
        },
        {
          path: "nfm-violations",
          name: "NFM Violations",
          component: NfmViolations
        },
        {
          path: "rp-validation",
          name: "RP Validation",
          component: RpValidation
        },
        {
          path: "Archive",
          name: "Archive",
          component: AlarmArchive
        },
        {
          path: "unauthorized-usage",
          name: "Unauthorized Usage",
          component: UnauthorizedUsage
        },
        {
          path: "top-aggressive",
          name: "Top Aggressive",
          component: TopAggressive
        },
        {
          path: "authenticate"
        }
      ]
    }
  ],
  linkExactActiveClass: "nav-item active",
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
});

router.beforeEach(async (to, from, next) => {
  if ("/authenticate" == to.path && to.query.code) {
    await store.dispatch("loadAuthTokens", to.query.code);
    await store.dispatch("loadAlarmActors");
    store.dispatch("loadAlarms");
    next("/");
    return;
  }

  if (!store.getters.getAuthTokens.id_token) {
    window.location = store.getters.getAuthenticationLoginUrl;
    return;
  }
  next();
});

Vue.use(VueRouter);
Vue.use(VueMaterial);
Vue.use(SideBar);

/* eslint-disable no-new */
new Vue({
  el: "#app",
  render: h => h(App),
  mounted() {
    setInterval(this.loadAlarms, 60 * 1000); // minute
    setInterval(this.loadAlarmActors, 86400 * 1000); // day
    setInterval(this.refreshAuthTokens, 3590 * 1000); // hour
  },
  methods: {
    ...mapActions(["loadAlarms", "loadAlarmActors", "refreshAuthTokens"])
  },
  router,
  store
});
