<template>
  <group-item
    v-if="group.items.length > 1"
    :group="group"
    :type="type"
    :selectable="selectable"
    @onItemClick="onItemClick"
  />
  <alarm-item
    v-else-if="group.items.length === 1"
    :alarm="group.items[0]"
    :type="type"
    :selectable="selectable"
    @onItemClick="onItemClick"
  />
</template>

<script>
  import GroupItem from "./GroupItem.vue";
  import AlarmItem from "./AlarmItem.vue";

  export default {
    name: "grouped-list-item",
    components: {
      "group-item": GroupItem,
      "alarm-item": AlarmItem
    },
    props: {
      group: {
        type: Object,
        default: () => {
          return {
            items: []
          }
        }
      },
      type: String,
      selectable: Boolean
    },
    methods: {
      onItemClick(alarm) {
        this.$emit("onItemClick", alarm);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .md-list-item {
    border-bottom: 1px solid rgba(#000, 0.12);
  }

  .md-list-item:last-child {
    border: none;
  }
</style>

<style lang="scss">
  // Override to fix margin being removed when ripple effect element is added and removed on click
  .md-icon:last-child {
    margin-left: inherit !important;
  }

  .grouped-list {
    .alarm-item-container, .group-item-container {
      margin: 0;

      .md-list-item-container {
        border: none;

        .md-ripple {
          border-radius: unset !important;
        }

        .md-list-expand {
          & > .md-list {
            background-color: #f4f4f4;
          }

          .md-list-item {
            margin: 0;
          }
        }
      }
    }
  }

.md-list.md-theme-default [disabled] {
  color: inherit !important;
}

.alarm-item-container {
  display: flex;
  flex-grow: 1;

  .md-list-item-container {
    .md-list-item-content {
      flex-direction: column;
      align-items: inherit;
    }
  }

  .comment-list {
    max-height: 0;
    transition: max-height 0.12s;
    transition-timing-function: ease-in-out;
    transition-delay: 0s;
    width: 100%;

    &.comment-list-expanded {
      max-height: 300px;
    }

    .comment-list-inner {
      visibility: hidden;
      height: 300px;
      overflow: auto;

      .md-field {
        label {
          top: 2em;
        }

        &.md-focused {
          label {
            top: 0;
          }
        }
      }

      textarea.md-textarea {
        min-height: 2em;
      }
    }

    &.comment-list-expanded {
      .comment-list-inner {
        visibility: visible;
      }
    }
  }
}

.alarm-item, .group-item {
  .action-button {
    display: inline-block;
    padding: 0 0.5em;

    button {
      margin: 0;
      min-width: 21px;
      width: 21px;
      height: 21px;

      .md-ripple {
        padding: 0 !important;

        .md-button-content {
          line-height: 21px;

          i {
            font-size: 12px !important;
          }
        }
      }
    }
  }

  .item-button {
    background: transparent !important;
    box-shadow: none;
    margin: 0;
    max-width: 24px;
    min-width: 0;

    .md-ripple {
      padding: 0 !important;

      i {
        color: gray !important;
        font-size: 24px !important;
      }
    }
  }
}
</style>