<template>
  <div class="list-search-filter">
    <md-field>
      <vue-select
        :value="getSearchValue(type)"
        :options="getFilterActors(type)"
        :name="type + '-search-filter'"
        :id="type + '-search-filter'"
        placeholder="Actor search..."
        @input="onSearchChange"
      />
    </md-field>
  </div>
</template>

<script>
  import vSelect from 'vue-select';
  import 'vue-select/dist/vue-select.css';
  import {mapActions, mapGetters} from "vuex";

  export default {
    name: "list-search-filter",
    components: {
      "vue-select": vSelect
    },
    props: {
      type: String
    },
    computed: {
      ...mapGetters(["getFilterActors", "getSearchValue"])
    },
    methods: {
      ...mapActions(["changeSearchFilter"]),
      onSearchChange(data) {
        this.changeSearchFilter({
          type: this.type,
          value: data
        });
      }
    }
  }
</script>

<style lang="scss">
  .list-search-filter {
    flex: 1 0 auto;

    .v-select {
      width: 100%;

      & > div {
        border: none;
      }

      input::placeholder {
        color: #aaa;
        font-size: 0.6875rem;
      }
    }
  }
</style>