<template>
  <div class="list-select-all-control">
    <md-button
      class="md-icon-button list-control-button"
      title="Toggle selection"
      @click.stop="onClick"
    >
      <md-icon>done_all</md-icon>
    </md-button>
  </div>
</template>

<script>
  import {mapActions, mapGetters} from "vuex";

  export default {
    name: "list-select-all-control",
    props: {
      type: String
    },
    methods: {
      ...mapActions(["toggleSelectAll"]),
      onClick() {
        this.toggleSelectAll(this.type);
      }
    }
  }
</script>

<style lang="scss">
  .list-select-all-control {
    display: flex;
    padding: 0.5em;
    padding-left: 0;
  }
</style>