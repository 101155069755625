<template>
  <div>
    <md-card-header v-if="hasTitle">
      <slot name="title"></slot>
    </md-card-header>
    <md-card-header
      class="card-chart"
      :data-background-color="dataBackgroundColor"
    >
      <div :id="chartId" class="ct-chart ct-one-five"></div>
    </md-card-header>

    <md-card-content v-if="hasContent">
      <slot name="content"></slot>
    </md-card-content>

    <md-card-actions md-alignment="left" v-if="hasFooter">
      <slot name="footer"></slot>
    </md-card-actions>
  </div>
</template>
<script>
export default {
  name: "chart-card",
  props: {
    footerText: {
      type: String,
      default: ""
    },
    headerTitle: {
      type: String,
      default: "Chart title"
    },
    chartType: {
      type: String,
      default: "Line" // Line | Pie | Bar
    },
    chartOptions: {
      type: Object,
      default: () => {
        return {};
      }
    },
    chartResponsiveOptions: {
      type: Array,
      default: () => {
        return [];
      }
    },
    chartData: {
      type: Object,
      default: () => {
        return {
          labels: [],
          series: []
        };
      }
    },
    dataBackgroundColor: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      chartId: "no-id"
    };
  },
  computed: {
    hasTitle() {
      return !!this.$slots.title;
    },
    hasContent() {
      return !!this.$slots.content;
    },
    hasFooter() {
      return !!this.$slots.footer;
    }
  },
  methods: {
    /***
     * Initializes the chart by merging the chart options sent via props and the default chart options
     */
    initChart() {
      var chartIdQuery = `#${this.chartId}`;
      this.$Chartist[this.chartType](
        chartIdQuery,
        this.chartData,
        this.chartOptions
      );
    },
    /***
     * Assigns a random id to the chart
     */
    updateChartId() {
      var currentTime = new Date().getTime().toString();
      var randomInt = this.getRandomInt(0, currentTime);
      this.chartId = `div_${randomInt}`;
    },
    getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }
  },
  mounted() {
    this.updateChartId();
    this.$nextTick(this.initChart);
  }
};
</script>
