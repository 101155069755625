<template>
  <div class="single-list">
    <div class="list-controls">
      <div class="list-control-row">
        <list-search-filter
          :type="type"
          class="list-control"
        />
      </div>
      <div class="list-control-row">
        <list-month-filter
          :type="type"
          class="list-control"
        />
        <list-sort-control
          :type="type"
          class="list-control"
        />
      </div>
    </div>
    <md-progress-bar
      md-mode="indeterminate"
      v-show="loading"
    />
    <md-list class="md-dense">
      <alarm-item
        v-for="alarm in alarms"
        :key="alarm.alarmId"
        :alarm="alarm"
        :type="type"
        :selectable="false"
        @onItemClick="onItemClick"
      />
    </md-list>
  </div>
</template>

<script>
  import AlarmItem from "./AlarmItem.vue";
  import ListMonthFilter from "./ListMonthFilter.vue"
  import ListSortControl from "./ListSortControl.vue"
  import ListSearchFilter from "./ListSearchFilter.vue"

  export default {
    name: "single-list",
    components: {
      "alarm-item": AlarmItem,
      "list-sort-control": ListSortControl,
      "list-month-filter": ListMonthFilter,
      "list-search-filter": ListSearchFilter
    },
    props: {
      loading: Boolean,
      type: String,
      alarms: {
        type: Array,
        default: () => {
          return []
        }
      }
    },
    methods: {
      onItemClick(alarm) {
        this.$emit("onItemClick", alarm);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .single-list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    & > .md-list {
      max-width: 100%;
      overflow: auto;
      vertical-align: top;
      display: flex;
      flex-grow: 1;
      border: 1px solid rgba(#000, .12);
      padding: 0;
      margin: 0;
    }

    & > .md-list {
      max-width: 100%;
      overflow: auto;
      vertical-align: top;
      display: flex;
      flex-grow: 1;
      border: 1px solid rgba(#000, .12);
      padding: 0;
    }
  }
</style>