<template>
  <spotfire-layout :appUrl="appUrl">
    <template v-slot:SpotfireSubLayout="subLayout">
      <div class="md-layout padded-sub-layout">
        <slot
          name="ListView"
          :onItemClick="subLayout.spotfireSetProperty"
        />
        <div
          id="spotfire"
          class="md-layout-item md-layout"
        ></div>
      </div>
    </template>
  </spotfire-layout>
</template>

<style lang="scss" scoped>
  .padded-sub-layout {
    padding: 5px;
  }
</style>

<script>
  import SpotfireLayout from "./SpotfireLayout.vue";
  export default {
    name: "spotfire-column-layout",
    components: {
      "spotfire-layout": SpotfireLayout
    },
    data() {
      return {};
    },
    props: {
      appUrl: String
    }
  }
</script>