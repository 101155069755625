<template>
    <md-list-item
      class="comment-body"
    >
      <div class="md-list-item-text">
        <span>{{ content }}</span>
        <span class="metadata">{{ user }} - {{ date }}</span>
      </div>
    </md-list-item>
</template>

<script>
  export default {
    name: "comment-list-item",
    props: {
      user: String,
      date: String,
      content: String
    }
  }
</script>

<style lang="scss" scoped>
  .comment-body {
    span {
      overflow: visible;
      text-overflow: unset;
      white-space: normal;
    }

    .metadata {
      padding: 0.5em 0;
      font-size: smaller;
    }

    &.no-comments {
      span {
        text-align: center;
      }
    }
  }
</style>