<template>
  <md-badge
    class="md-square md-dense"
    :class="getStyles"
    :md-content="getStateText"
  />
</template>

<script>
  export default {
    name: "state-indicator",
    props: {
      state: String,
      count: Number,
      total: Number
    },
    computed: {
      getStyles() {
        return {
          active: this.state === "Active",
          normal: this.state === "Back to normal"
        }
      },
      getStateText() {
        if (this.count !== undefined && this.state === "Active") {
          return `(${this.count}/${this.total}) ${this.state}`;
        }

        return this.state;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .active {
    background-color: #ff5252;
  }

  .normal {
    background-color: #4caf50;
  }
</style>