<template>
    <div :class="{'comment-list-expanded': expanded}">
      <div class="comment-list-inner">
        <md-list
          v-if="comments.length > 0"
          class="md-double-line md-dense"
        >
          <comment-input-item
            v-if="!disabled"
            :alarm="alarm"
            :numComments="comments.length"
          />
          <comment-list-item
            v-for="comment in comments"
            :key="comment.attribute_values.date + comment.attribute_values.user"
            :date="comment.attribute_values.date"
            :user="comment.attribute_values.user"
            :content="comment.attribute_values.message"
          />
        </md-list>
        <md-list
          v-else
          class="md-double-line md-dense"
        >
          <comment-input-item
            v-if="!disabled"
            :alarm="alarm"
          />
          <comment-list-item
            date=""
            user=""
            content="No comments yet"
            class="no-comments"
          />
        </md-list>
      </div>
    </div>
</template>

<script>
  import CommentListItem from "./CommentListItem.vue"
  import CommentInputItem from "./CommentInputItem.vue"

  export default {
    name: "comment-list",
    components: {
      "comment-list-item": CommentListItem,
      "comment-input-item": CommentInputItem
    },
    data: () => ({
      expanded: false
    }),
    props: {
      disabled: Boolean,
      comments: {
        type: Array,
        default: () => []
      },
      alarm: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    mounted() {
      setTimeout(() => this.expanded = true, 100)
    }
  }
</script>