import Vue from "vue";
import { sortAlarms } from "./helpers.js";

const alarmIsEqual = (a, b) => (
  a.actor === b.actor &&
  a.protocol === b.protocol &&
  a.detectionPrinciple === b.detectionPrinciple
);

const alarmGrouper = (groups, alarm) => {
  if (!groups[alarm.actor]) {
    groups[alarm.actor] = {
      ...alarm,
      timeslot: "",
      items: [],
      activeCount: 0,
      state: ""
    };
  }

  if (alarm.timeslot > groups[alarm.actor].timeslot) {
    groups[alarm.actor].timeslot = alarm.timeslot;
  }

  groups[alarm.actor].items.push(alarm);
  if (alarm.state === "Active") {
    groups[alarm.actor].activeCount += 1;
    groups[alarm.actor].state = alarm.state;
  }

  if (!groups[alarm.actor].state) {
    groups[alarm.actor].state = alarm.state;
  }

  return groups;
};

const defaultAlarmId = (alarm) => (`${alarm.actor}${alarm.detectionPrinciple}${alarm.protocol}`)
const archiveAlarmId = (alarm) => (`${alarm.timestamp}${alarm.actor}${alarm.protocol}`)
const deriveActorType = (id) => (id.length === 8 && id.indexOf("01") === 0 ? "Customer" : "Operator")

const formatAlarm = (alarmActors, alarm, idFormat = defaultAlarmId) => {
  let actor = alarmActors[alarm.actor]

  if (actor) {
    actor = {
      actorName: actor.name || alarm.actor,
      actorPriority: actor.priority,
      actorCountry: actor.country,
      actorType: actor.actorType
    }
  } else {
    actor = {
      actorName: alarm.actor,
      actorType: deriveActorType(alarm.actor)
    }
  }

  return {
    ...alarm,
    ...actor,
    alarmName: `${alarm.protocol}-${alarm.detectionPrinciple}`,
    alarmId: idFormat(alarm)
  };
}

const alarmReducer = (alarmActors = {}) => (result, alarm) => {
  const alarmObj = formatAlarm(alarmActors, alarm);

  if (alarm.acknowledged === "true") {
    result.ack.push(alarmObj);
  } else {
    result.unack.push(alarmObj);
  }

  result.alarms.push(alarmObj);

  return  result;
}

const setAlarms = (state, payload) => {
  const {
    alarms,
    unack,
    ack
  } = payload.alarms.reduce(
    alarmReducer(state.alarmActors),
    {
      alarms: [],
      unack: [],
      ack: []
    }
  )

  const sortedAlarms = sortAlarms(alarms);
  const sortedAck = sortAlarms(ack, state.sorting.acknowledged);
  const sortedUnack = sortAlarms(unack, state.sorting.unacknowledged);

  const ackGroups = sortedAck.reduce(alarmGrouper, {});
  const unackGroups = sortedUnack.reduce(alarmGrouper, {});

  const alarmGroups = {
    acknowledged: ackGroups,
    unacknowledged: unackGroups
  }

  const ackGroupsArr = Object.keys(ackGroups).map((key) => ackGroups[key]);
  const unackGroupsArr = Object.keys(unackGroups).map((key) => unackGroups[key]);

  const sortedAlarmGroups = {
    acknowledged: sortAlarms(ackGroupsArr, state.sorting.acknowledged),
    unacknowledged: sortAlarms(unackGroupsArr, state.sorting.unacknowledged)
  }

  state.alarms = sortedAlarms
  state.alarmGroups = alarmGroups
  state.sortedAlarmGroups = sortedAlarmGroups
};

export default {
  setError: (state, message = null) => {
    (state.error = {
      ts: new Date(),
      msg: message || null
    })

    Vue.notify({
      group: 'message',
      type: 'error',
      duration: 10000,
      title: 'Error Occurred!',
      text: state.error.ts.toLocaleString() + " - " + state.error.msg
    })
  },
  setSuccess: (state, message = null) => {
    (state.success = {
      ts: new Date(),
      msg: message || null
    })

    Vue.notify({
      group: 'message',
      type: 'success',
      duration: 5000,
      title: 'Success!',
      text: state.success.ts.toLocaleString() + " - " + state.success.msg
    })
  },
  setAuthTokens: (
    state,
    { id_token, access_token, refresh_token, expires_in, token_type }
  ) => {
    if (id_token) {
      Vue.set(state.authenticationTokens, "id_token", id_token);
    }
    if (access_token) {
      Vue.set(state.authenticationTokens, "access_token", access_token);
    }
    if (refresh_token) {
      Vue.set(state.authenticationTokens, "refresh_token", refresh_token);
    }
    if (expires_in) {
      Vue.set(state.authenticationTokens, "expires_in", expires_in);
    }
    if (token_type) {
      Vue.set(state.authenticationTokens, "token_type", token_type);
    }
  },
  setAlarms,
  delAlarms: (state, {alarms: removedAlarms}) => {
    const ids = removedAlarms.map((alarm) => (
      `${alarm.actor}${alarm.detectionPrinciple}${alarm.protocol}`
    ));

    const prunedAlarms = state.alarms.filter((alarm) => {
      return ids.indexOf(alarm.alarmId) === -1;
    });

    setAlarms(state, {alarms: prunedAlarms});
  },
  refreshAlarms: (state, {alarms}) => {
    const currentAlarms = [...state.alarms];

    alarms.forEach((alarm) => {
      const alarmId = `${alarm.actor}${alarm.detectionPrinciple}${alarm.protocol}`;
      const existingIndex = currentAlarms.findIndex((item) => {
        return item.alarmId === alarmId;
      });

      if (existingIndex >= 0) {
        const updAlarm = {
          ...currentAlarms[existingIndex],
          ...alarm
        };

        currentAlarms[existingIndex] = updAlarm;
      }

      setAlarms(state, {alarms: currentAlarms});
    })
  },
  setAlarmActors: (state, actors = []) => {
    state.alarmActors = actors.reduce((dict, actor) => {
      actor['actorType'] = deriveActorType(actor.id)

      dict[actor.id] = actor;
      return dict;
    }, {});
  },
  toggleSelectAll: (state, {type}) => {
    const selection = state.alarms.reduce((result, alarm) => {
      if (alarm.acknowledged === "true" && type === "acknowledged") {
        result[alarm.alarmId] = true;
      } else if (type === "unacknowledged") {
        result[alarm.alarmId] = true;
      }

      return result;
    }, {});

    const current = state.selectedAlarms[type] || {};
    if (Object.keys(selection).length > Object.keys(current).length) {
      state.selectedAlarms[type] = selection;
    } else {
      state.selectedAlarms[type] = {};
    }
  },
  selectAlarms: (state, {alarms, type}) => {
    state.selectedAlarms[type] = alarms.reduce((selected, alarm) => {
      selected[alarm.alarmId] = true;

      return selected;
    }, {...state.selectedAlarms[type]});
  },
  deselectAlarms: (state, {alarms, type}) => {
    state.selectedAlarms[type] = alarms.reduce((selected, alarm) => {
      selected[alarm.alarmId] = false;

      return selected;
    }, {...state.selectedAlarms[type]});
  },
  resetAlarmSelection: (state) => {
    state.selectedAlarms = {
      acknowledged: {},
      unacknowledged: {}
    };
  },
  toggleLoading(state, {type, status}) {
    state.loading[type] = status;
  },
  updateSorting(state, {type, opts}) {
    state.sorting[type] = {
      ...state.sorting[type],
      ...opts
    };

    setAlarms(state, {alarms: state.alarms});
  },
  activateSpotfire(state, alarm) {
    state.activeSpotfire = alarm;
  },
  setArchivedAlarms(state, payload) {
    const alarms = payload.alarms.map((alarm) => {
      return formatAlarm(state.alarmActors, alarm, archiveAlarmId)
    })

    state.archive = {
      ...state.archive,
      loading: false,
      alarms
    }
  },
  loadingArchivedAlarms(state) {
    state.archive.loading = true
  },
  updateMonthFilter(state, payload) {
    state.filter = {
      ...state.filter,
      [payload.type]: {
        ...state.filter[payload.type],
        month: payload.value
      }
    }
  },
  updateSearchFilter(state, payload) {
    state.filter = {
      ...state.filter,
      [payload.type]: {
        ...state.filter[payload.type],
        search: payload.value
      }
    }
  }
};
