<template>
  <div>
    <slot
      name="SpotfireSubLayout"
      :spotfireSetProperty="spotfireSetProperty"
    />
    <md-dialog
      :md-active.sync="showDialog"
      @md-closed="loadScript"
    >
      <iframe :src="this.getSpotfireLoginUrl" id="login"></iframe>
    </md-dialog>
  </div>
</template>

<script>
  import {
    mapGetters,
    mapActions,
    mapMutations,
    mapState
  } from "vuex";

  const spotfireInit = (appUrl) => {
    if (!spotfire || !spotfire.webPlayer || !appUrl) {
      return;
    }

    const customizationInfo = new spotfire.webPlayer.Customization();
    customizationInfo.showStatusBar = false;
    customizationInfo.showToolBar = false;
    customizationInfo.showPageNavigation = true;
    customizationInfo.showDodPanel = false;

    window.app = new spotfire.webPlayer.Application(
      "https://spotfire.int.tcxn.net/spotfire/wp/",
      customizationInfo,
      appUrl
    );

    window.doc = window.app.openDocument("spotfire", "overview");
    return doc;
  }

  export default {
    name: "spotfire-layout",
    data() {
      return {
        spotfireDoc: null,
        showDialog: false
      };
    },
    computed: {
      ...mapGetters([
        "getSpotfireLoginUrl",
        "getSpotfireScriptUrl"
      ])
    },
    props: {
      appUrl: String
    },
    mounted() {
      if (window.spotfire) {
        if (window.spotfire.webPlayer) {
          this.spotfireDoc = spotfireInit(this.appUrl);
        } else {
          this.loadScript();
        }
      } else {
        this.loadScript();
      }

      this.setSuccess("Spotfire loaded successfully!");
    },
    methods: {
      ...mapMutations(["setSuccess"]),
      loadScript() {
        const script = document.createElement("script");
        script.async = true;
        script.src = this.getSpotfireScriptUrl;
        script.id = "spotfireScript";

        const headerTag =
          document.getElementsByTagName("head")[0] ||
          document.getElementsByTagName("body")[0];

        script.onload = () => {
          if (!window.spotfire || !window.spotfire.webPlayer) {
            this.showDialog = true;
          } else {
            this.spotfireDoc = spotfireInit(this.appUrl);
            this.spotfireSetProperty(null);
          }
        };

        headerTag.appendChild(script);
      },
      spotfireSetProperty(alarm) {
        this.$store.dispatch("setActiveSpotfire", alarm)

        const {
          actor = "",
          timeslot = "",
          protocol = "",
          actorName = "",
          actorCountry = ""
        } = alarm || {};

        // When this parameter is changed, graphs configured to be loaded on-demand will be cleared
        window.doc.setDocumentProperty("CleaningParameter", actor+protocol+timeslot);
        window.doc.setDocumentProperty("ActorID", actor);
        window.doc.setDocumentProperty("Timeslot", timeslot);
        window.doc.setDocumentProperty("Protocol", protocol);
        window.doc.setDocumentProperty("ActorName", actorName);
        window.doc.setDocumentProperty("ActorCountry", actorCountry);
      }
    }
  };
</script>