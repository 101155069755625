<template>
  <spotfire-full-layout :appUrl="getSpotfireApplications.topAggressiveApplicationUrl" />
</template>

<script>
  import {
    mapGetters
  } from "vuex";
  import SpotfireFullLayout from "./Layout/SpotfireFullLayout.vue";

  export default {
    name: "top-aggressive",
    components: {
      "spotfire-full-layout": SpotfireFullLayout
    },
    data() {
      return {};
    },
    computed: {
      ...mapGetters([
        "getSpotfireApplications"
      ])
    }
  };
</script>
