<template>
  <div>
    <md-badge
      v-if="showBadge"
      class="md-danger md-dense md-danger comment-badge"
      :md-content="content"
    >
      <slot></slot>
    </md-badge>
    <slot v-else-if="!showBadge"></slot>
  </div>
</template>

<script>
  export default {
    name: "badged-button",
    props: {
      showBadge: Boolean,
      content: String
    }
  }
</script>

<style lang="scss">
.comment-badge {
  .md-badge {
    position: absolute;
    top: -3px;
    right: -3px;
    bottom: unset;
    width: 12px;
    height: 12px;
    background-color: #ff5252 !important;
  }
}
</style>