<template>
  <md-list-item
    class="alarm-item-container"
    :class="{'active-item': getIsActiveSpotfire(alarm)}"
    @click.stop="noop"
    :md-ripple="false"
  >
    <div class="alarm-item">
      <div>
        <md-checkbox
          v-if="selectable"
          v-model="selected"
          :md-ripple="false"
        />
      </div>
      <div class="alarm-item-info">
        <span>{{ alarm.actorName }} <i v-if="alarm.actorCountry"> | {{ alarm.actorCountry }}</i> </span>
        <span><i v-if="alarm.actorPriority">{{ alarm.actorPriority }}, </i>{{ alarm.actorType }} ({{ alarm.actor }}), {{ alarm.protocol }}</span>
        <span>{{ alarm.timeslot }}</span>
      </div>
      <badged-button
        :showBadge="comments.length > 0"
        :content="'' + comments.length"
        class="action-button"
      >
        <md-button
          class="md-just-icon"
          :class="commentBtnStyle"
          @click.stop="toggleComments"
        >
          <md-tooltip>View Comments</md-tooltip>
          <md-icon>comment</md-icon>
        </md-button>
      </badged-button>
      <state-indicator
        :state="alarm.state"
      />
      <md-button
        class="md-icon-button item-button"
        :md-ripple="false"
        @click.stop="onItemClick"
      >
        <md-tooltip>Show Spotfire Data</md-tooltip>
        <md-icon>chevron_right</md-icon>
      </md-button>
    </div>
    <comment-list
      v-if="viewComments"
      class="comment-list"
      :comments="comments"
      :alarm="alarm"
      :disabled="!selectable"
    />
  </md-list-item>
</template>
<script>
  import {mapActions, mapGetters} from "vuex";
  import StateIndicator from "./StateIndicator.vue";
  import BadgedButton from "./BadgedButton.vue"
  import CommentList from "./CommentList.vue"

  export default {
    name: "alarm-item",
    components: {
      "state-indicator": StateIndicator,
      "badged-button": BadgedButton,
      "comment-list": CommentList
    },
    data: () => ({
      viewComments: false
    }),
    props: {
      type: String,
      selectable: Boolean,
      alarm: {
        type: Object,
        default: () => {
          return {
            actor: "",
            timeslot: "",
            state: "",
            comments: []
          }
        }
      }
    },
    computed: {
      ...mapGetters([
        "getSelectedState",
        "getIsActiveSpotfire"
      ]),
      selected: {
        get() {
          return this.getSelectedState({
            alarmOpts: {
              alarmId: this.alarm.alarmId,
              type: this.type
            }})
        },
        set(value) {
          this.selectAlarm({
            selected: !value,
            type: this.type,
            alarm: this.alarm
          })
        }
      },
      comments() {
        if (typeof this.alarm.comments === "string") {
            return JSON.parse(this.alarm.comments)
        }

        return []
      },
      commentBtnStyle() {
        return {
          "md-warning": this.comments.length >= 1,
          "md-info": this.comments.length < 1,
          "opened": this.viewComments
        }
      }
    },
    methods: {
      ...mapActions(["selectAlarm"]),
      toggleComments() {
        this.viewComments = !this.viewComments;
      },
      onItemClick() {
        this.$emit("onItemClick", this.alarm);
      },
      noop() {
        // Used to prevent checkbox in the item to be changed
        // due to vue-material behaviour
      }
    }
  }
</script>

<style lang="scss">
.alarm-item-container {
  display: flex;
  flex-grow: 1;
  margin: 0;

  .md-list-item-container {
    &:hover {
      background-color: inherit !important;
    }

    .md-list-item-content.md-ripple {
      cursor: initial !important;
      user-select: text !important;
    }
  }

  .alarm-item {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex-grow: 1;
    align-items: center;
    width: 100%;

    .alarm-item-info {
      flex-grow: 1;
      overflow: hidden;

      span {
        line-height: 1.4em;
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;
        font-size: smaller;
      }

      span:first-child {
        font-size: inherit;
      }
    }
  }

  &.active-item {
    background-color: #d3f8fd;

    .alarm-item-info {
      span:first-child {
        font-weight: bold;
      }
    }
  }
}
</style>