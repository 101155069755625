<template>
    <md-list-item class="comment-body">
      <div class="comment-input">
        <md-field>
          <label>Add a comment...</label>
          <md-textarea
            v-model="comment"
          />
        </md-field>
        <md-button
            class="md-raised md-dense"
            @click="addComment"
          >
            <span>Add</span>
          </md-button>
      </div>
    </md-list-item>
</template>

<script>
  import {mapActions} from "vuex";

  export default {
    name: "comment-input-item",
    data: () => ({
      comment: "",
    }),
    props: {
      numComments: {
        type: Number,
        default: 0
      },
      alarm: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    methods: {
      ...mapActions([
        "commentAlarm",
        "loadAlarms"
      ]),
      async addComment() {
        const newComment = {
          id: this.numComments + 1,
          date: new Date().toLocaleString(),
          user: "",
          message: this.comment
        }

        const resp = await this.commentAlarm({
          ...this.alarm,
          comments: [newComment]
        })

        this.comment = ""
        if (resp.status === 200) {
          this.loadAlarms()
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .comment-input {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    button {
      background-color: #00bcd4 !important;

      &:hover, &:active {
        background-color: #00bcd4 !important;
      }
    }
  }
</style>