import axios from "axios";
import Vue from "vue";
import queryString from "query-string";

const getAlarmsForAction = (state, type) => {
  const alarms = state.alarms.reduce((list, alarm) => {
    if (state.selectedAlarms[type][alarm.alarmId]) {
      list.push({
        actor: alarm.actor,
        protocol: alarm.protocol,
        detectionPrinciple: alarm.detectionPrinciple
      });
    }

    return list;
  }, []);

  if (alarms.length === 0) {
    throw new Error("NoAlarmsFound");
  }

  return alarms;
}

const alarmAction = async (state, commit, dispatch, method, url, payload, type, onDone) => {
  const args = {
    method,
    url: [state.alarmApi.endpoint, url].join("/"),
    headers: {
      Authorization: state.authenticationTokens.id_token
    },
    data: payload
  };

  let result;
  try {
    result = await axios(args);
    commit("resetAlarmSelection");
    commit(onDone, {alarms: result.data.alarms});
  } catch (err) {
    dispatch("handleError", err);
    return;
  }

  return result;
}

export default {
  loadAuthTokens: async ({ commit, getters, dispatch }, code) => {
    try {
      const result = await axios({
        method: "POST",
        url: getters.getAuthenticationOauthUrl,
        headers: { "content-type": "application/x-www-form-urlencoded" },
        data: queryString.stringify({
          grant_type: "authorization_code",
          client_id: getters.getAuthenticationClientId,
          redirect_uri: getters.getAuthenticationRedirectUri,
          code: code
        })
      });

      commit("setAuthTokens", result.data);
      return result.data;
    } catch (error) {
      dispatch("handleError", error)
      return;
    }
  },
  refreshAuthTokens: async ({ commit, getters, dispatch }) => {
    try {
      const result = await axios({
        method: "POST",
        url: getters.getAuthenticationOauthUrl,
        headers: { "content-type": "application/x-www-form-urlencoded" },
        data: queryString.stringify({
          grant_type: "refresh_token",
          client_id: getters.getAuthenticationClientId,
          refresh_token: getters.getAuthTokens.refresh_token
        })
      });

      commit("setAuthTokens", result.data);
      return result.data;
    } catch (error) {
      dispatch("handleError", error)
      return;
    }
  },
  setAlarmApiAccessToken: ({ commit }, accessToken) => {
    commit("setAlarmApiAccessToken", accessToken)
  },
  handleError: ({ commit, getters }, error) => {
    let msg = "Unknown error";
    if (error.response) {
      // The request was made and the server responded with a status code that falls out of the range of 2xx
      if (
        401 === error.response.status &&
        ["Unauthorized", "The incoming token has expired"].includes(
          error.response.data.message
        )
      ) {
        window.location = getters.getAuthenticationLoginUrl;
      }

      msg =
        error.response.data.message ||
        error.response.data.errorMessage ||
        `Error code ${error.response.status}`;
    } else if (error.request) {
      // The request was made but no response was received
      msg = error.request;
    }
    commit("setError", msg);
  },
  loadAlarms: async ({ commit, state, dispatch }) => {
    let result;

    try {
      result = await axios.get(`${state.alarmApi.endpoint}/alarms`, {
        headers: {
          Authorization: `${state.authenticationTokens.id_token}`
        }
      });
    } catch (e) {
      dispatch("handleError", e);
    }

    dispatch("checkResponse", {action: "Load alarms", result});

    commit("setAlarms", {
      alarms: result.data.alarms || [],
      reload: true
    });
  },
  setAlarms: ({ commit }, alarms) => (
    commit("setAlarms", { alarms })
  ),
  delAlarms: ({ commit }, alarms) => (
    commit("delAlarms", alarms)
  ),
  loadAlarmActors: async({ commit, state, dispatch }) => {
    try {
      const result = await axios.get(`${state.alarmApi.endpoint}/refdata`, {
        headers: {
          Authorization: `${state.authenticationTokens.id_token}`
        }
      })

      commit("setAlarmActors", result.data.actors || []);
      return result.data;
    } catch (error) {
      dispatch("handleError", error)
      return;
    }
  },
  commentAlarm: async ({ commit, state, dispatch }, alarm_data) => {
    try {
      const result = await axios({
        method: "PATCH",
        url: `${state.alarmApi.endpoint}/alarms/comment`,
        headers: {  Authorization: `${state.authenticationTokens.id_token}` },
        data: alarm_data
      })

      const action = 'Comment alarm'
      dispatch("checkResponse", {result, action})
      return result
    } catch (error) {
      dispatch("handleError", error)
      return 'error'
    }
  },
  checkResponse: ({ commit, getters }, obj) => {
    if (obj.result.status == 200) {
      commit("setSuccess", obj.action);
    } else {
      var msg = obj.action + ' ::: ' + obj.result.status + " - " + obj.result.statusText;
      commit("setSuccess", msg);
    }
  },
  toggleSelectAll({commit}, type) {
    commit("toggleSelectAll", {type});
  },
  selectGroup({state, commit}, {selected, type, actor}) {
    const alarms = state.alarmGroups[type][actor].items;

    if (selected) {
      commit("deselectAlarms", {alarms, type});
    } else {
      commit("selectAlarms", {alarms, type});
    }
  },
  selectAlarm({commit}, {selected, type, alarm}) {
    if (selected) {
      commit("deselectAlarms", {alarms: [alarm], type});
    } else {
      commit("selectAlarms", {alarms: [alarm], type});
    }
  },
  acknowledgeAlarms: async ({state, commit, dispatch}, {type, salesforce}) => {
    let alarms;

    try {
      alarms = getAlarmsForAction(state, type)
    } catch (err) {
      commit("setError", "Select at least 1 alarm");
      return;
    }

    if (alarms.length === 0) {
      commit("setError", "Select at least 1 alarm");
      return;
    }

    commit("toggleLoading", {type, status: true})

    const payload = {alarms}

    if (salesforce) {
      payload.salesforce = salesforce;
    }

    await alarmAction(
      state,
      commit,
      dispatch,
      "patch",
      "alarms/acknowledge",
      payload,
      type,
      "refreshAlarms"
    )

    commit("toggleLoading", {type, status: false})
  },
  closeAlarms: async ({state, commit, dispatch}, {type, noAction}) => {
    let alarms;

    try {
      alarms = getAlarmsForAction(state, type);
    } catch (err) {
      commit("setError", "Select at least 1 alarm");
      return;
    }

    commit("toggleLoading", {type, status: true});

    const payload = {alarms};

    if (noAction) {
      payload.noAction = noAction;
    }

    await alarmAction(
      state,
      commit,
      dispatch,
      "delete",
      "alarms",
      payload,
      type,
      "delAlarms"
    );

    commit("toggleLoading", {type, status: false});
  },
  changeSorting: ({commit}, payload) => {
    commit("updateSorting", payload);
  },
  setActiveSpotfire: ({commit}, payload) => {
    commit("activateSpotfire", payload);
  },
  loadArchivedAlarms: async ({ commit, state, getters, dispatch }) => {
    let result;

    commit("loadingArchivedAlarms")

    const month = getters.getMonthFilter("archived")

    try {
      result = await axios.get(`${state.alarmApi.endpoint}/archive/${month}`, {
        headers: {
          Authorization: `${state.authenticationTokens.id_token}`
        }
      });
    } catch (e) {
      dispatch("handleError", e);
      return;
    }

    commit("setArchivedAlarms", {
      alarms: result.data.alarms || [],
      loading: false
    });
  },
  changeMonthFilter: ({commit, dispatch}, payload) => {
    commit("updateMonthFilter", payload)
    dispatch("loadArchivedAlarms")
  },
  changeSearchFilter: ({commit}, payload) => {
    commit("updateSearchFilter", payload)
  }
};
