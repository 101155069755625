<template>
  <div class="list-sort-control">
    <md-field>
      <label :for="type + '-sort-control'">Sort on</label>
      <md-select
        v-model="selected"
        md-dense
        :name="type + '-sort-control'"
        :id="type + '-sort-control'"
      >
        <md-option value="timeslot">Timeslot</md-option>
        <md-option value="actorName">Actor</md-option>
        <md-option value="state">State</md-option>
        <md-option value="protocol">Protocol</md-option>
      </md-select>
    </md-field>
    <md-button
      class="md-icon-button list-control-button"
      :title="(direction === 'desc') ? 'Descending' : 'Ascending'"
      :class="direction"
      @click.stop="onDirectionClick(direction)"
    >
      <md-icon>sort</md-icon>
    </md-button>
  </div>
</template>

<script>
  import {mapActions, mapGetters} from "vuex";

  export default {
    name: "list-sort-control",
    props: {
      type: String
    },
    computed: {
      ...mapGetters(["getSorting"]),
      selected: {
        get() {
          return this.getSorting(this.type).attr
        },
        set(value) {
          // This might currently cause a vue-material warning (which is caused by a bug),
          // no fix has been released yet. Does not appear to cause any trouble though.
          this.changeSorting({
            type: this.type,
            opts: {
              attr: value
            }
          })
        }
      },
      direction: {
        get() {
          return this.getSorting(this.type).direction
        },
        set(value) {
          this.changeSorting({
            type: this.type,
            opts: {
              direction: value
            }
          })
        }
      }
    },
    methods: {
      ...mapActions(["changeSorting"]),
      onDirectionClick(value) {
        this.direction = (value === "desc") ? "asc" : "desc"
      }
    }
  }
</script>

<style lang="scss">
  .list-sort-control {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 0.5em 0;
    flex: 1 0 0;

    .md-field {
      flex: 1 0 0;
      width: 100px;
      padding: 0;
      margin: 0;
      height: unset;
      min-height: unset;

      .md-select {
        .md-input {
          padding-bottom: 0;
          padding-top: 1em;
        }

        .md-icon {
          margin-bottom: 0;
        }
      }
    }

    .list-control-button {
      margin-left: 0.5em;
    }
  }

  .md-select-menu {
    .md-list-item-text {
      position: unset;
    }
  }
</style>