<template>
  <spotfire-column-layout :appUrl="getSpotfireApplications.alarmApplicationUrl">
    <template v-slot:ListView="listView">
      <div class="column-layout-panel md-layout-item md-layout">
        <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
          <md-card>
            <md-card-header data-background-color="blue">
              <h4 class="title">Unacknowledged</h4>
            </md-card-header>
            <md-card-content>
              <grouped-list
                salesforceOption
                type="unacknowledged"
                submitText="Acknowledge"
                :groups="getUnacknowledgedAlarmGroups"
                @onItemClick="listView.onItemClick"
                @onListAction="acknowledgeAlarms"
              />
              <md-divider />
            </md-card-content>
          </md-card>
        </div>
        <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
          <md-card>
            <md-card-header data-background-color="blue">
              <h4 class="title">Acknowledged</h4>
            </md-card-header>
            <md-card-content>
              <grouped-list
                noActionOption
                type="acknowledged"
                submitText="Close"
                :groups="getAcknowledgedAlarmGroups"
                @onItemClick="listView.onItemClick"
                @onListAction="closeAlarms"
              />
              <md-divider />
            </md-card-content>
          </md-card>
        </div>
      </div>
    </template>
  </spotfire-column-layout>
</template>

<script>
import SpotfireColumnLayout from "./Layout/SpotfireColumnLayout.vue";
import { GroupedList } from "@/components";
import {
  mapGetters,
  mapActions,
  mapMutations,
  mapState
} from "vuex";

export default {
  name: "alarm-list",
  components: {
    "grouped-list": GroupedList,
    "spotfire-column-layout": SpotfireColumnLayout
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      "getUnacknowledgedAlarmGroups",
      "getAcknowledgedAlarmGroups",
      "getSpotfireApplications"
    ])
  },
  methods: {
    ...mapActions([
      "acknowledgeAlarms",
      "closeAlarms"
    ])
  }
};
</script>
