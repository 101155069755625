<template>
  <md-list-item md-expand class="group-item-container" :md-expanded.sync="expanded">
    <div class="group-item">
      <md-checkbox
        v-if="selectable"
        v-model="selected"
      />
      <div class="group-item-info">
        <span>({{ group.items.length }}) {{ group.actorName }} <i v-if="group.actorCountry"> | {{ group.actorCountry }}</i> </span>
        <span><i v-if="group.actorPriority">{{ group.actorPriority }}, </i>{{ group.actorType }} ({{ group.actor }})</span>
        <span>{{ group.timeslot }}</span>
      </div>
      <badged-button
        :showBadge="hasComments"
        content=""
        class="action-button"
      >
        <md-button
          class="md-just-icon"
          disabled
        >
          <md-tooltip>Comment indicator</md-tooltip>
          <md-icon>comment</md-icon>
        </md-button>
      </badged-button>
      <state-indicator
        :total="group.items.length"
        :count="group.activeCount"
        :state="group.state"
      />
    </div>
    <md-list slot="md-expand">
      <div v-if="expanded">
        <alarm-item
          v-for="alarm in group.items"
          :key="alarm.alarmId"
          :alarm="alarm"
          :type="type"
          :selectable="selectable"
          @onItemClick="onItemClick"
        />
      </div>
    </md-list>
  </md-list-item>
</template>

<script>
  import {mapActions, mapGetters} from "vuex";

  import AlarmItem from "./AlarmItem.vue";
  import StateIndicator from "./StateIndicator.vue";
  import BadgedButton from "./BadgedButton.vue";

  export default {
    name: "group-item",
    components: {
      "alarm-item": AlarmItem,
      "state-indicator": StateIndicator,
      "badged-button": BadgedButton
    },
    data: () => ({
      expanded: false
    }),
    props: {
      type: String,
      selectable: Boolean,
      group: {
        type: Object,
        default: () => {
          return {
            items: []
          }
        }
      }
    },
    computed: {
      ...mapGetters(["getSelectedState"]),
      hasComments() {
        return this.group.items.some((item) => {
          return item.comments && item.comments.length > 2;
        })
      },
      selected: {
        get() {
          return this.getSelectedState({
            groupOpts: {
              actor: this.group.actor,
              type: this.type
            }
          })
        },
        set() {
          this.selectGroup({
            selected: this.selected,
            type: this.type,
            actor: this.group.actor
          })
        }
      }
    },
    methods: {
      ...mapActions(["selectGroup"]),
      onItemClick(alarm) {
        this.$emit("onItemClick", alarm);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .group-item {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex-grow: 1;
    align-items: center;
    width: 100px;
    flex: 1 0 0;

    .group-item-info {
      flex-grow: 1;
      overflow: hidden;

      span {
        line-height: 1.4em;
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;
        font-size: smaller;
      }

      span:first-child {
        font-size: inherit;
      }
    }
  }
</style>