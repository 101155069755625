<template>
  <div class="grouped-list">
    <div class="list-controls">
      <div class="list-control-row">
        <list-select-all-control
          :type="type"
          class="list-control"
        />
        <list-sort-control
          :type="type"
          class="list-control"
        />
      </div>
    </div>
    <md-list class="md-dense">
      <grouped-list-item
        v-for="group in groups"
        :key="group.actor"
        :group="group"
        :type="type"
        :selectable="listHasAction"
        @onItemClick="onItemClick"
      />
    </md-list>
    <alarm-list-actions
      v-if="listHasAction"
      :type="type"
      :salesforceOption="salesforceOption"
      :noActionOption="noActionOption"
      :submitText="submitText"
      @onListAction="onListAction"
    />
  </div>
</template>

<script>
  import GroupedListItem from "./GroupedListItem.vue";
  import AlarmListActions from "./AlarmListActions.vue";
  import ListSortControl from "./ListSortControl.vue"
  import ListSelectAllControl from "./ListSelectAllControl.vue"

  export default {
    name: "grouped-list",
    components: {
      "grouped-list-item": GroupedListItem,
      "alarm-list-actions": AlarmListActions,
      "list-sort-control": ListSortControl,
      "list-select-all-control": ListSelectAllControl
    },
    props: {
      type: String,
      submitText: String,
      salesforceOption: Boolean,
      noActionOption: Boolean,
      groups: {
        type: Array,
        default: () => {
          return []
        }
      }
    },
    computed: {
      listHasAction() {
        return this.$listeners && this.$listeners.onListAction !== undefined
      }
    },
    methods: {
      onItemClick(alarm) {
        this.$emit("onItemClick", alarm);
      },
      onListAction(opts) {
        this.$emit("onListAction", opts);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .grouped-list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    & > .md-list {
      max-width: 100%;
      overflow: auto;
      vertical-align: top;
      display: flex;
      flex-grow: 1;
      border: 1px solid rgba(#000, .12);
      padding: 0;
      margin: 0;
    }

    & > .md-list {
      max-width: 100%;
      overflow: auto;
      vertical-align: top;
      display: flex;
      flex-grow: 1;
      border: 1px solid rgba(#000, .12);
      padding: 0;
    }

    .list-control-row {
      justify-content: space-between;
    }
  }
</style>