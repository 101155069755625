<template>
  <div class="list-actions">
    <md-progress-bar
      md-mode="indeterminate"
      v-show="getActionLoading(type)"
    />
    <md-checkbox
      v-if="salesforceOption"
      v-model="salesforce"
    >
      <span>Salesforce</span>
    </md-checkbox>
    <md-checkbox
      v-if="noActionOption"
      v-model="noAction"
    >
      <span>No Action</span>
    </md-checkbox>
    <md-button v-on:click="onListAction()">
      <span>{{ submitText }}</span>
    </md-button>
  </div>
</template>

<script>
  import {mapActions, mapGetters} from "vuex";

  export default {
    name: "alarm-list-actions",
    data: () => ({
      salesforce: false,
      noAction: false
    }),
    props: {
      salesforceOption: Boolean,
      noActionOption: Boolean,
      submitText: String,
      type: String
    },
    computed: {
      ...mapGetters(["getActionLoading"])
    },
    methods: {
      onListAction(opts) {
        this.$emit("onListAction", {
          salesforce: this.salesforce,
          noAction: this.noAction,
          type: this.type
        });
      }
    }
  }
</script>

<style lang="scss" scoped>
  .list-actions {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    button {
      background-color: #00bcd4 !important;

      &:hover, &:active {
        background-color: #00bcd4 !important;
      }
    }
  }
</style>