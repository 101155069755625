export default {
  prod: {
    alarmApi: {
      endpoint: "https://v5smr4647j.execute-api.eu-west-1.amazonaws.com/prod"
    },
    authentication: {
      url: "mm-skynet-alarm-management-prod.auth.eu-west-1.amazoncognito.com",
      client_id: "273otff4a7r73d7d0k1i9emvgo",
      redirect_uri: "https://skynet.tcxn.net/authenticate"
    },
    spotfire: {
      loginUrl: "https://spotfire.int.tcxn.net/spotfire/login.html",
      scriptUrl: "https://spotfire.int.tcxn.net/spotfire/wp/GetJavaScriptApi.ashx?Version=10.10",
      applications: {
        alarmApplicationUrl: "1d3bf134-ae0d-457e-914a-c8124c98aaf0",
        alarmHistoryApplicationUrl: "58a7b1c7-9240-419d-b22d-14831a8f5a75",
        nfmApplicationUrl: "f49bf61a-61b9-4d39-88b6-2653a63300ec",
        rpValidationApplicationUrl: "78352399-c576-43c5-882f-043ebe99c058",
        unauthorizedUsageApplicationUrl: "6eed9d39-156b-4ae2-9ba6-424cffb431f8",
        topAggressiveApplicationUrl: "142376fc-55c5-4e20-8236-ec92de1792d4",
        archiveApplicationUrl: "52ee2f6d-3144-4ad8-a1ec-cee9f0d2d608"
      }
    }
  },

  uat: {
    alarmApi: {
      endpoint: "https://3wncn9djrh.execute-api.eu-west-1.amazonaws.com/uat"
    },
    authentication: {
      url: "mm-skynet-alarm-management-uat.auth.eu-west-1.amazoncognito.com",
      client_id: "46mb4mpuuain0csb68nporkueo",
      redirect_uri: "https://skynet-uat.tcxn.net/authenticate"
    },
    spotfire: {
      loginUrl: "https://spotfire.int.tcxn.net/spotfire/login.html",
      scriptUrl: "https://spotfire.int.tcxn.net/spotfire/wp/GetJavaScriptApi.ashx?Version=10.10",
      applications: {
        alarmApplicationUrl: "3ed8a487-72ad-435e-b48b-755e7c1ef3bb",
        alarmHistoryApplicationUrl: "dfeb6687-d9a7-45a6-a69e-97704d47f882",
        nfmApplicationUrl: "f49bf61a-61b9-4d39-88b6-2653a63300ec",
        rpValidationApplicationUrl: "78352399-c576-43c5-882f-043ebe99c058",
        unauthorizedUsageApplicationUrl: "6eed9d39-156b-4ae2-9ba6-424cffb431f8",
        topAggressiveApplicationUrl: "142376fc-55c5-4e20-8236-ec92de1792d4",
        archiveApplicationUrl: "52ee2f6d-3144-4ad8-a1ec-cee9f0d2d608"
      }
    }
  },

  dev: {
    alarmApi: {
      endpoint: "https://n3xezw48o6.execute-api.eu-west-1.amazonaws.com/dev"
    },
    authentication: {
      url: "mm-skynet-alarm-management-dev.auth.eu-west-1.amazoncognito.com",
      client_id: "75btejejqv8cdjnrslprspvv6v",
      redirect_uri: "http://localhost:8080/authenticate" // "https://d5r6tochx6ye7.cloudfront.net/authenticate" //
    },
    spotfire: {
      loginUrl: "https://spotfire.int.tcxn.net/spotfire/login.html",
      scriptUrl: "https://spotfire.int.tcxn.net/spotfire/wp/GetJavaScriptApi.ashx?Version=10.10",
      applications: {
        alarmApplicationUrl: "3ed8a487-72ad-435e-b48b-755e7c1ef3bb",
        nfmApplicationUrl: "f49bf61a-61b9-4d39-88b6-2653a63300ec",
        rpValidationApplicationUrl: "78352399-c576-43c5-882f-043ebe99c058",
        unauthorizedUsageApplicationUrl: "6eed9d39-156b-4ae2-9ba6-424cffb431f8",
        topAggressiveApplicationUrl: "142376fc-55c5-4e20-8236-ec92de1792d4",
        archiveApplicationUrl: "52ee2f6d-3144-4ad8-a1ec-cee9f0d2d608"
      }
    }
  }
};
