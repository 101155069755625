export const sortAlarms = (
  alarms,
  opts = {
    attr: "timeslot",
    direction: "desc"
  }
) => {
  const {attr, direction} = opts;
  const [greater, lesser] = (direction === "asc") ? [1, -1] : [-1, 1];

  return alarms.sort((a, b) => {
    if (a[attr] > b[attr]) {
      return greater;
    } else if (a[attr] < b[attr]) {
      return lesser;
    }

    const fallbackAttr = attr === "timeslot" ? "actorName" : "timeslot";

    // If first comparison is equal always sort on either timeslot or actor
    // as a secondary sort
    if (a[fallbackAttr] > b[fallbackAttr]) {
      return greater;
    } else if (a[fallbackAttr] < b[fallbackAttr]) {
      return lesser;
    }

    return 0;
  });
};